
import styled, {css} from 'styled-components'
import PropTypes from 'prop-types'
import {media} from '../../../utils'
import {BaseLink} from '../../base'


const StyledPointContainer = styled.div`
  position: absolute;
  top: calc(${({top}) => top}% - 1.75rem);
  left: calc(${({left}) => left}% - 1.75rem);
  border-radius: 0.5rem;

  &:hover,
  &:focus {
    transition: background-color ${({theme}) => `${theme.global.duration} ease-in`};
    z-index: 2;
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: ${({theme}) => theme.colors.background};
    width: 15rem;
    height: 4.5rem;

    ${media.down('mobile')(css`
      height: 5.5rem;
      width: 10rem;
    `)}
  }
`

const StyledPoint = styled.div`
  position: relative;
  top: 1.75rem;
  left: 1.75rem;
  transform: translate(calc(-50% + 0.25rem), calc(-50% + 0.25rem));
  z-index: 1;
  border: 1px solid ${({theme}) => theme.colors.primary};
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.background};
  cursor: pointer;
  width: 28px;
  height: 28px;
  content: '';

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid ${({theme}) => theme.colors.primary};
    border-radius: 50%;
    padding: 8px;
    content: '';
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: ${({theme}) => theme.colors.primary};
    width: 6px;
    height: 6px;
    content: '';
  }

  ${media.down('mobile')(css`
    top: 2.25rem;
    left: 1.5rem;
  `)}
`

const StyledDescription = styled(BaseLink)`
  position: absolute;
  top: 1rem;
  left: 4.5rem;
  z-index: -1;
  text-align: left;
  line-height: 1.25;
  color: ${({theme}) => theme.colors.secondary};

  ${StyledPointContainer}:hover &,
  focus & {
    z-index: 1;
  }

  ${media.down('mobile')(css`
    top: 0.75rem;
    left: 3.5rem;
  `)}
`

const PointItem = ({top, left, link, text}) => (
  <StyledPointContainer
      top={top}
      left={left}
  >
    <StyledPoint />
    <StyledDescription to={link}>{text}</StyledDescription>
  </StyledPointContainer>
)

PointItem.propTypes = {
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default PointItem
