import {useState} from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {isEmpty} from 'lodash-es'
import {media} from '../../../utils'
import {ContentContainer, Typography, BaseButton, BaseLink} from '../../base'
import Image from '../../base/Image/Image'
import Button from '../Button/Button'
import PointsList from './PointsList'


const StyledContentContainer = styled(ContentContainer)`
  padding-top: 2rem;

  ${media.down('mobile')(css`
    padding-top: 0;
  `)}
`

const HotelsContainer = styled.div`
  overflow: hidden;
  text-align: center;
`

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;

  ${media.down('mobile')(css`
    justify-content: space-around;
  `)}
`

const StyledHotelButton = styled(BaseButton)`
  margin: 0 4rem;
  border: 0;
  background: transparent;

  ${({isActive}) => isActive && css`
    color: ${({theme}) => theme.colors.primary};
  `}

  ${media.down('mobile')(css`
    margin: 0;
  `)}
`

const StyledTitle = styled(Typography)`
  text-transform: uppercase;

  ${media.down('mobile')(css`
    font-size: 1.5rem;
  `)}
`

const TagWrapper = styled.div`
  ${({empty}) => empty && css`
    height: 2rem;
  `}

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 3rem;
`

const StyledTag = styled(Typography)`
  margin: 0 1rem;
  text-transform: uppercase;
  color: gray;

  ${media.down('mobile')(css`
    margin: 0 0.4rem;
  `)}
`

const StyledHotelsContainer = styled.div`
  position: relative;
`

const StyledImage = styled(Image)`
  display: inline-block;
  align-items: center;
  width: 100%;
`

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
`

const StyledButton = styled(Button)`
  width: 15rem;
`

const SectionForHotels = ({collections, poi, offer}) => {
  const [activeCollection, setActiveCollection] = useState(0)

  const handleActiveCollection = (index) => setActiveCollection(index)

  return (
    <StyledContentContainer>
      <HotelsContainer>
        <TextWrapper>
          <For each="collection" of={collections}>
            <If condition={!isEmpty(collection.type)}>
              <StyledHotelButton
                  key={collection.index}
                  onClick={() => handleActiveCollection(collection.index)}
                  isActive={collections[activeCollection].index === collection.index}
              >
                <StyledTitle type="h2">
                  {collection.type}
                </StyledTitle>
              </StyledHotelButton>
            </If>
          </For>
        </TextWrapper>
        <StyledHotelsContainer>
          <If condition={
            !isEmpty(poi) &&
            !isEmpty(collections[0]?.photo?.srcSet) ||
            !isEmpty(collections[activeCollection]?.photo?.srcSet)}
          >
            <PointsList points={poi} />
          </If>
          <Choose>
            <When condition={!isEmpty(collections[activeCollection]?.products)}>
              <TagWrapper>
                <For each="product" of={collections[activeCollection]?.products} index="idx">
                  <StyledTag variant="p" key={idx}>
                    {product.product}
                  </StyledTag>
                </For>
              </TagWrapper>
              <StyledImage
                  loading="lazy"
                  url={collections[activeCollection]?.photo?.srcSet}
                  alt={collections[activeCollection]?.photo?.altText}
              />
            </When>
            <Otherwise>
              <TagWrapper empty />
              <StyledImage
                  loading="lazy"
                  url={collections[0]?.photo?.srcSet}
                  alt={collections[0]?.photo?.altText}
              />
            </Otherwise>
          </Choose>
        </StyledHotelsContainer>
      </HotelsContainer>
      <If condition={!isEmpty(offer?.offerEmail) && !isEmpty(offer?.offerText)}>
        <StyledButtonContainer>
          <BaseLink to={`mailto: ${offer.offerEmail}`} isExternal>
            <StyledButton>
              {offer.offerText}
            </StyledButton>
          </BaseLink>
        </StyledButtonContainer>
      </If>
    </StyledContentContainer>
  )
}

SectionForHotels.propTypes = {
  collections: PropTypes.array.isRequired,
  poi: PropTypes.array,
  offer: PropTypes.object,
}

export default SectionForHotels
