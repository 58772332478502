
import PropTypes from 'prop-types'
import {isEmpty} from 'lodash-es'
import PointItem from './PointItem'


const PointsList = ({points}) => (
  <>
    <If condition={!isEmpty(points)}>
      <For each="point" of={points} index="idx">
        <PointItem
            key={idx}
            top={Number(point.yPosition)}
            left={Number(point.xPosition)}
            link={point.url}
            text={point.text}
        />
      </For>
    </If>
  </>
)

PointsList.Item = PointItem

PointsList.propTypes = {
  points: PropTypes.array,
}

export default PointsList
